import React, {useEffect, useState} from 'react'
import BrandLogo from '../assets/scout-living-logo.png'
import {useGetReservationWebKeysQuery} from '../services/guestWebKeysApi'
import WebKeyView from './webkey/WebKeyView'
import CheckedOutView from './webkey/CheckedOutView'
import RevokedView from './webkey/RevokedView'
import {getLogger} from '../services/logging'
import LoadingView from './webkey/LoadingView'
import ErrorView from './webkey/ErrorView'
import {ReservationStatus} from '../types'

enum AppView {
  LoadingView = 'LoadingView',
  WebKeyView = 'WebKeyView',
  CheckedOutView = 'CheckedOutView',
  RevokedView = 'RevokedView',
  ErrorView = 'ErrorView'
}

interface AppState {
  view: AppView
  webKeyId?: string
  error?: string
}

const logger = getLogger('App')

// TODO: remove this after testing elevators
const ELEVATOR_WEBKEYS = [
  {
    id: 'Hotel Elevator - 27 Access',
    name: 'Elevator C',
    description: '',
    group: 'Common'
  },
  {
    id: 'Hotel Elevator - 28 Access',
    name: 'Elevator B',
    description: '',
    group: 'Common'
  },
  {
    id: 'Hotel Elevator - 29 Access',
    name: 'Elevator A',
    description: '',
    group: 'Common'
  },
  {
    id: 'Hotel Elevator - F5 Access -FRT',
    name: 'Elevator D',
    description: '',
    group: 'Common'
  },
  {
    id: 'Hotel Elevator - F5 Access -REAR',
    name: 'Elevator',
    description: 'Parking Garage Entrance',
    group: 'Common'
  }
]

export default function App() {
  const [appState, setAppState] = useState<AppState>({
    view: AppView.LoadingView,
    webKeyId: null,
    error: null
  })
  // TODO: remove this after testing elevators
  const [isElevatorFeatureEnabled, setElevatorFeatureEnabled] = useState<
    boolean
  >(false)
  const reservationWebKeysQuery = useGetReservationWebKeysQuery(
    appState.webKeyId,
    {
      skip: !appState.webKeyId
    }
  )

  const reservation = reservationWebKeysQuery.data?.reservation
  // const doors = reservationWebKeysQuery.data?.doors

  // TODO: remove this after testing elevators
  let doors = reservationWebKeysQuery.data?.doors
  if (isElevatorFeatureEnabled && doors && doors.length > 0) {
    doors = [...doors, ...ELEVATOR_WEBKEYS]
  }

  // TODO: remove this after testing elevators
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const elevatorsFeature = urlParams.get('elevators')
    if (elevatorsFeature === 'true') {
      setElevatorFeatureEnabled(true)
      logger.info({message: 'Elevator feature is enabled'})
    }
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const webKeyId = urlParams.get('webKeyId')
    if (webKeyId) {
      logger.info({message: 'WebKey ID found in URL', data: {webKeyId}})

      setAppState({
        ...appState,
        webKeyId
      })
    } else {
      logger.error({message: 'WebKey ID not found in URL'})

      setAppState({
        ...appState,
        view: AppView.ErrorView,
        error: 'WebKey ID not found.'
      })
    }
  }, [])

  useEffect(() => {
    if (reservation) {
      logger.info({
        message: 'Successfully retrieved reservation',
        data: {reservation}
      })

      if (reservation.isAccessRevoked) {
        logger.warn({message: 'Access has been revoked for reservation'})
        setAppState({...appState, view: AppView.RevokedView})
      } else if (reservation.status === ReservationStatus.InHouse) {
        logger.info({message: 'Reservation is in house'})
        setAppState({...appState, view: AppView.WebKeyView})
      } else if (reservation.status === ReservationStatus.CheckedOut) {
        logger.warn({message: 'Reservation has already checked out'})
        setAppState({...appState, view: AppView.CheckedOutView})
      } else {
        logger.warn({
          message: 'Reservation cannot use web keys.',
          data: {
            status: reservation.status,
            isAccessRevoked: reservation.isAccessRevoked
          }
        })
        setAppState({...appState, view: AppView.ErrorView})
      }
    } else {
      if (reservationWebKeysQuery.isError) {
        logger.error({
          message: 'Failed to retrieve reservation for web key ID',
          data: {webKeyId: appState.webKeyId}
        })
        setAppState({...appState, view: AppView.ErrorView})
      }
    }
  }, [reservation, reservationWebKeysQuery])

  return (
    <main className="flex flex-col gap-2">
      <div className="w-full max-w-screen-sm mx-auto min-h-screen bg-white flex flex-col">
        <div className="w-full bg-primary grow-0 py-4">
          <img src={BrandLogo} className="w-24 mx-auto" />
        </div>
        {appState.view === AppView.LoadingView ? <LoadingView /> : ''}
        {appState.view === AppView.WebKeyView ? (
          <WebKeyView
            webKeyId={appState.webKeyId}
            doors={doors}
            checkinUrl={reservation?.checkinUrl}
          />
        ) : (
          ''
        )}
        {appState.view === AppView.CheckedOutView ? <CheckedOutView /> : ''}
        {appState.view === AppView.RevokedView ? <RevokedView /> : ''}
        {appState.view === AppView.ErrorView ? (
          <ErrorView error={appState.error} />
        ) : (
          ''
        )}
      </div>
    </main>
  )
}
